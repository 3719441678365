@font-face {
  font-family: 'Geometria-Bold';
  src: url('/assets/fonts/Geometria-Bold.otf');
}

.root {
  display: flex;
  flex-direction: column;
}

.head {
  display: flex;
}

.page {
  display: flex;
}

.header {
  font-family: Geometria-Bold;
  padding-top: 1%;
  margin: 0% 0% 2% 5%;
  font-weight: 600;
  font-size: 72px;
  text-shadow: #f2f2f2 -1px -1px 0, #f2f2f2 1px 1px 0, #f2f2f2 1px -1px 0,
    #f2f2f2 -2px 2px 0, #090909 -2px -2px 0px, #f2f2f2 -2px -4px 0px;
  color: red;
}

.menu {
  color: white;
  font-weight: 500;
  font-family: Geometria;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15%;
  cursor: pointer;
  user-select: none;
}
.menu:hover {
  background-color: red;
}

.mainMenu {
  display: flex;
  flex-direction: column;
  margin: 30px 0px 20px 0px;
}

.white {
  width: 200px;
  height: 300px;
  background-color: white;
  margin-left: 100px;
  margin-top: 50px;
  z-index: 2;
}
.red {
  width: 200px;
  height: 300px;
  background-color: red;
  margin-left: -180px;
  margin-top: 40px;
  z-index: 1;
}
.black {
  width: 200px;
  height: 300px;
  background-color: black;
  margin-left: -185px;
  margin-top: 30px;
  z-index: 0;
  border: 1px solid white;
}

.left {
  flex-direction: row;
  display: flex;
  margin-left: 5%;
}

.right {
  color: #f2f2f2;
  margin-left: 8%;
  width: 39%;
}

.name {
  font-family: Geometria-Bold;
  position: relative;
  font-size: 64px;
  line-height: 91px;
  letter-spacing: 0.015em;
  z-index: 2;
  text-align: center;
}
.stripe {
  position: relative;
  width: 100%;
  height: 18px;
  background-color: red;
  top: 71px;
  z-index: 1;
}

.description {
  font-family: Geometria;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: 0.015em;
  color: white;
}
.links {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}
.link {
  margin-bottom: 1%;
  cursor: pointer;
  text-decoration: red underline;
}

.navMenu {
  margin-right: 120px;
}

.navMenu:hover {
  background-color: red;
}

.navigation {
  margin-top: 6%;
  margin-left: 100px;
}

.navLink {
  color: white;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-family: Geometria;
  font-size: 24px;
  line-height: 30px;
}

@media screen and (max-width: 1000px) {
  .white {
    display: none;
  }
  .red {
    display: none;
  }
  .black {
    display: none;
  }
  .navMenu {
    margin-right: 10%;
  }
  .page {
    flex-direction: column;
  }
  .mainMenu {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .menu {
    margin: 2% 1%;
  }
  .right {
    width: 80%;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 800px) {
  .header {
    font-size: 48px;
  }
  .navMenu {
    margin-right: 5%;
  }
  .navigation {
    margin-left: 5%;
  }
  .navLink {
    font-size: 16px;
  }
  .menu {
    font-size: 16px;
  }
  .mainMenu {
    margin-bottom: 0;
  }
  .description {
    font-size: 16px;
    line-height: 18px;
  }
  .links {
    font-size: 12px;
    line-height: 18px;
  }
  .name {
    font-size: 60px;
    line-height: 70px;
  }
  .description {
    margin-top: 5%;
  }
}
@media screen and (max-width: 400px) {
  .header {
    font-size: 28px;
  }
  .navLink {
    font-size: 12px;
  }
  .navMenu {
    margin-right: 1%;
  }
  .name {
    font-size: 38px;
  }
}
