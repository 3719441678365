@font-face {
  font-family: 'Geometria-Bold';
  src: url('/assets/fonts/Geometria-Bold.otf');
}

.index {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.header {
  margin-top: 10%;
  font-weight: 600;
  font-size: 72px;
  text-align: center;
  font-family: Geometria-Bold;
}
.blurred {
  text-shadow: #f2f2f2 -1px -1px 0, #f2f2f2 1px 1px 0, #f2f2f2 1px -1px 0,
    #f2f2f2 1px -1px 0, #090909 2px 3px 0px, #f2f2f2 3px 4px 0px;
  color: #101010;
}

.blurredActive {
  text-shadow: #f2f2f2 -1px -1px 0, #f2f2f2 1px 1px 0, #f2f2f2 1px -1px 0,
    #f2f2f2 -2px 2px 0, #090909 -2px -2px 0px, #f2f2f2 -2px -4px 0px;
  color: red;
}
.technologies {
  color: white;
}

.link {
  text-decoration: none;
  color: white;
  font-family: Geometria;
  font-size: 24px;
  line-height: 30px;
  cursor: pointer;
  user-select: none;
}

.menu {
  margin-right: 120px;
}

.menu:hover {
  background-color: red;
}

.navigation {
  margin-top: 3%;
  margin-left: 8%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .header {
    font-size: 48px;
  }
  .link {
    font-size: 16px;
  }
  .menu {
    margin-right: 22%;
  }
  .navigation {
    margin-left: 3%;
  }
}
@media screen and (max-width: 400px) {
  .header {
    margin-top: 15%;
    font-size: 38px;
    text-align: left;
  }
  .link {
    font-size: 32px;
    margin-bottom: 5%;
  }
  .menu {
    padding-bottom: 10%;
    margin-right: 0%;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
  }
}
