@font-face {
  font-family: 'Geometria-Bold';
  src: url('/assets/fonts/Geometria-Bold.otf');
}
.root {
  display: flex;
  flex-direction: column;
}

.head {
  display: flex;
}

.page {
  display: flex;
}

.header {
  font-family: Geometria-Bold;
  padding-top: 1%;
  margin: 0% 0% 1% 5%;
  font-weight: 600;
  font-size: 72px;
  text-shadow: #f2f2f2 -1px -1px 0, #f2f2f2 1px 1px 0, #f2f2f2 1px -1px 0,
    #f2f2f2 -2px 2px 0, #090909 -2px -2px 0px, #f2f2f2 -2px -4px 0px;
  color: red;
}

.navMenu {
  margin-right: 120px;
}

.navMenu:hover {
  background-color: red;
}

.navigation {
  margin-top: 4%;
  margin-left: 100px;
}

.navLink {
  color: white;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-family: Geometria;
  font-size: 24px;
  line-height: 30px;
}

.menu {
  color: white;
  font-weight: 500;
  font-family: Geometria;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15%;
  cursor: pointer;
  user-select: none;
}
.menu:hover {
  background-color: red;
}

.mainMenu {
  display: flex;
  flex-direction: column;
  margin: 30px 0px 20px 0px;
}

.left {
  flex-direction: row;
  display: flex;
  margin-left: 5%;
}

.right {
  color: #f2f2f2;
  margin-left: 8%;
  width: 60%;
}

.name {
  font-family: Geometria-Bold;
  position: relative;
  font-size: 64px;
  line-height: 91px;
  letter-spacing: 0.015em;
  z-index: 2;
  text-align: center;
  margin-top: -30px;
}
.stripe {
  position: relative;
  width: 100%;
  height: 18px;
  background-color: red;
  top: 41px;
  z-index: 1;
}

.description {
  letter-spacing: 0.015em;
  color: white;
  margin-left: 70px;
  font-family: Geometria;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  width: 70%;
}
.links {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}
.link {
  margin-bottom: 1%;
  cursor: pointer;
  text-decoration: red underline;
}

.content {
  display: flex;
}

.photo {
  width: 342px;
  height: 423px;
}

.networks {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  margin-top: 2%;
  cursor: pointer;
  margin-bottom: 2%;
}

.network {
  margin-left: 1%;
  margin-right: 5%;
}
.network:hover {
  color: red;
}

@media screen and (max-width: 1200px) {
  .navMenu {
    margin-right: 8%;
  }
  .page {
    flex-direction: column;
  }
  .mainMenu {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .menu {
    margin: 2% 1%;
  }
  .right {
    width: 80%;
  }
  .photo {
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 1000px) {
  .photo {
    width: 250px;
    height: 330px;
  }
  .description {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .navMenu {
    margin-right: 5%;
  }
  .navigation {
    margin-left: 10%;
  }
  .navLink {
    font-size: 16px;
  }
  .menu {
    font-size: 16px;
  }
  .mainMenu {
    margin-bottom: 0;
  }
  .name {
    line-height: 70px;
    margin-bottom: 5%;
  }
  .photo {
    width: 200px;
    height: 280px;
  }
  .description {
    margin-left: 4%;
    font-size: 12px;
  }
  .header {
    font-size: 50px;
  }
}
@media screen and (max-width: 400px) {
  .navMenu {
    margin-right: 2%;
  }
  .navigation {
    margin-left: 5%;
  }
  .header {
    font-size: 32px;
  }
  .navLink {
    font-size: 12px;
  }
  .mainMenu {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .photo {
    width: 100px;
    height: 150px;
  }
  .description {
    font-size: 8px;
    line-height: 10px;
  }
  .network {
    width: 20px;
    height: 20px;
  }
  .name {
    font-size: 32px;
    line-height: 48px;
  }
  .stripe {
    top: 20px;
  }
}
