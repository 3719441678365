@font-face {
  font-family: 'Geometria-Bold';
  src: url('/assets/fonts/Geometria-Bold.otf');
}

.root {
  display: flex;
  flex-direction: column;
}

.head {
  display: flex;
}
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  justify-content: center;
}
.header {
  font-family: Geometria-Bold;
  padding-top: 1%;
  margin: 0% 0% 2% 5%;
  font-weight: 600;
  font-size: 72px;
  text-shadow: #f2f2f2 -1px -1px 0, #f2f2f2 1px 1px 0, #f2f2f2 1px -1px 0,
    #f2f2f2 -2px 2px 0, #090909 -2px -2px 0px, #f2f2f2 -2px -4px 0px;
  color: red;
}

.about {
  font-family: Geometria;
  font-size: 18px;
  line-height: 32px;
  font-weight: lighter;
  letter-spacing: 0.015em;
  color: white;
  width: 60%;
}

.links {
  margin-top: 3%;
  margin-bottom: 1%;
  font-family: Geometria;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 0.015em;
  color: white;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.link {
  cursor: pointer;
  user-select: none;
  text-decoration: red underline;
}
.link:hover {
  color: red;
}
.navMenu {
  margin-right: 120px;
}

.navMenu:hover {
  background-color: red;
}

.navigation {
  margin-top: 6%;
  margin-left: 100px;
}

.navLink {
  color: white;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  font-family: Geometria;
  font-size: 24px;
  line-height: 30px;
}

@media screen and (max-width: 1200px) {
  .navMenu {
    margin-right: 15%;
  }
}

@media screen and (max-width: 800px) {
  .navMenu {
    margin-right: 5%;
  }
}
@media screen and (max-width: 600px) {
  .header {
    font-size: 48px;
  }
  .navigation {
    margin-left: 6%;
  }
  .navLink {
    font-size: 16px;
  }
  .about {
    font-size: 16px;
    line-height: 18px;
  }
}

@media screen and (max-width: 400px) {
  .header {
    font-size: 28px;
  }
  .navLink {
    font-size: 12px;
  }
  .about {
    margin-top: 5%;
    width: 80%;
    line-height: 18px;
  }
  .links {
    margin-top: 10%;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: space-between;
  }
  .link {
    margin-right: 5%;
  }
}
